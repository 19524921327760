<script setup lang="ts">
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { useI18n } from 'vue-i18n';
import type { Alert } from '~/types/alerts';

dayjs.extend(weekOfYear);

// Composables
const { formatNumberToIsoEuroNumber } = useNumbers();
const { t } = useI18n();
const resetZoom = ref(false);
const { getCo2byKWhByMeterId } = useCO2();
// Props
const emits = defineEmits<{
  (e: 'label-click', value: any): void;
}>();
const props = defineProps<{
  datasets: any[];
  data: any[];
  meterId: number;
  timestamps: string[];
  aggregationLevel: string;
  alert: Alert | null;
  loading: boolean;
}>();

// Methods
const onLabelClick = (event: any) => {
  emits('label-click', {
    id: props.data[event.datasetIndex].id,
    name: props.data[event.datasetIndex].name,
    consumption: props.data[event.datasetIndex].consumption[event.index],
  });
};

// Computed
const alertThreshold = computed(() => {
  return props.alert ? props.alert.params.standard_value * (1 + (props.alert.params.tolerance ?? 0) / 100) : 0;
});

const options = computed(() => {
  return {
    scales: {
      y: {
        stacked: true,
        beginAtZero: true,
        title: {
          display: true,
          text: `${t('analytics.consumption')} (kWh)`,
          padding: 12,
        },
        grace: '25%',
      },
      x: {
        stacked: true,
        ticks: {
          callback: (value: any) => {
            const date = dayjs(props.timestamps[value]);
            if (props.aggregationLevel === 'week') {
              return dayjs(date).locale('fr').format('YYYY-') + t('global.short_week', { value: dayjs(date).locale('fr').week() });
            } else if (props.aggregationLevel === 'day') {
              return dayjs(date).locale('fr').format('ddd D MMM');
            } else if (props.aggregationLevel === 'month') {
              return dayjs(date).locale('fr').format('MMMM YYYY');
            }
          },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const label = context.dataset.label || '';
            const value = context.parsed.y || 0;
            return `${label}: ${Math.round(value)} kWh`;
          },
          title: (context: any) => {
            const date = new Date(context[0].label);
            if (props.aggregationLevel === 'week') {
              return dayjs(date).locale('fr').format('YYYY-') + t('global.short_week', { value: dayjs(date).locale('fr').week() });
            } else if (props.aggregationLevel === 'day') {
              return dayjs(date).locale('fr').format('dddd D MMMM YYYY');
            } else if (props.aggregationLevel === 'month') {
              return dayjs(date).locale('fr').format('MMMM YYYY');
            }
          },
          footer: (context) => {
            const footer = [];
            const meter = props.data[context[0].datasetIndex].consumption[context[0].dataIndex];

            if (props.data[context[0].datasetIndex].pourcent[context[0].dataIndex]) {
              footer.push(
                `${t('global.distribution')}: ${Math.round(props.data[context[0].datasetIndex].pourcent[context[0].dataIndex])}%`,
              );
            }

            if (props.aggregationLevel === 'week') {
              //datasetIndex
              footer.push(`${t('global.start')}: ${dayjs(props.timestamps[context[0].dataIndex]).format('DD/MM/YYYY')}`);
              footer.push(`${t('global.end')}: ${dayjs(props.timestamps[context[0].dataIndex]).add(6, 'days').format('DD/MM/YYYY')}`);
            }

            footer.push(`${t('global.cost')}: ${formatNumberToIsoEuroNumber(meter.cost)}`);
            footer.push(`${t('global.co2')}: ${((meter.consumption_kwh / 1000) * getCo2byKWhByMeterId(props.meterId)).toFixed(2)}kg`);
            return footer;
          },
        },
      },
      annotation: {
        annotations: {
          alertStandardAnnotation: {
            type: 'line',
            borderColor: 'rgba(150, 0, 0, 0.6)',
            borderWidth: 2,
            scaleID: 'y',
            value: alertThreshold.value === 0 ? null : alertThreshold.value,
          },
        },
      },
    },
  };
});

watch(
  () => appStore().getExploreDateRange,
  () => {
    resetZoom.value = true;
  },
);
</script>

<template>
  <div class="relative">
    <div v-if="loading" class="absolute z-10 flex items-center justify-center bg-opacity-50 bg-gray-100 top-0 left-0 right-0 bottom-0">
      <ui-loader />
    </div>
    <ui-chart
      type="bar"
      :data="{
        labels: timestamps,
        datasets: datasets,
      }"
      :options="options"
      :plugins="{
        'chartjs-plugin-zoom': true,
        'chartjs-plugin-annotation': true,
        legend: true,
      }"
      :reset-zoom="resetZoom"
      @label-click="onLabelClick"
    />
  </div>
</template>
