<script setup lang="ts">
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { useFilter } from '../../composables/useFilter';
import { useConsumption } from '../../composables/useConsumption';

// Components
import ConsumptionGraph from '~/app-modules/explore/components/graphs/ConsumptionGraph.vue';
import ConsumptionZoomModal from '~/app-modules/explore/components/modal/ConsumptionZoomModal.vue';
import { useI18n } from 'vue-i18n';

dayjs.extend(weekOfYear);

// Composables
const { t } = useI18n();
const route = useRoute();
const { getAggregateFilterOption, getAggregationMeter } = useFilter();
const { getAlertTypeFromAggregationLevel } = useConsumption();

// Props
defineEmits<{
  (e: 'update-date-range', value: { start: string; end: string }): void;
}>();
const props = defineProps<{
  dateRange: { start: string; end: string };
}>();

const { datasets, timestamps, data, loading, childMeters, reload } = useExplore({
  dateRange: props.dateRange,
});

// Data
const modal = ref(false);
const zoomModal = ref(false);
const zoomPeriod = ref<{ start: string; end: string } | null>(null);
const zoomMeter = ref<number | null>(null);

// Load data
const toast = inject('toast') as any;

// Computed
const aggregationLevel = computed(() => {
  return appStore().getAggregationLevel;
});

// Methods
const onLabelClick = (event: any) => {
  if (event.id === 0) return;

  const start = dayjs(event.consumption.date);
  const end = dayjs(event.consumption.date);
  const meter = meterStore().getMeter(parseInt(route.params.id as string));

  if (meter && meter.meter_type.name === 'grdf') {
    toast.message = t('global.can_not_zoom_on_grdf_meter');
    toast.success = true;
    return;
  }

  zoomMeter.value = event.id;
  if (aggregationLevel.value === 'day') {
    zoomPeriod.value = { start: start.startOf('day').format('YYYY-MM-DD'), end: end.endOf('day').format('YYYY-MM-DD') };
  } else if (aggregationLevel.value === 'week') {
    zoomPeriod.value = {
      start: start.startOf('day').format('YYYY-MM-DD'),
      end: end.add(6, 'days').endOf('day').format('YYYY-MM-DD'),
    };
  } else if (aggregationLevel.value === 'month') {
    zoomPeriod.value = {
      start: start.startOf('day').startOf('month').format('YYYY-MM-DD'),
      end: end.endOf('month').endOf('day').format('YYYY-MM-DD'),
    };
  }
  zoomModal.value = true;
};

// Computed
const totalConsumption = computed(() => {
  return data.value
    .map((d: any) => {
      return d.consumption.map((d: any) => {
        return d.consumption_kwh;
      });
    })
    .flat()
    .reduce((acc: number, curr: number) => acc + curr, 0);
});
const totalCost = computed(() => {
  return data.value
    .map((d: any) => {
      return d.consumption.map((d: any) => {
        return d.cost;
      });
    })
    .flat()
    .reduce((acc: number, curr: number) => acc + curr, 0);
});
const alert = computed(() => {
  return alertStore().getAlertByMeterIdAndType(
    parseInt(route.params.id as string),
    getAlertTypeFromAggregationLevel(aggregationLevel.value),
  );
});
const aggregateOption = computed(() => getAggregateFilterOption());
const showByOption = computed(() => getAggregationMeter());

const title = computed(() => {
  const start = dayjs(props.dateRange.start);
  const end = dayjs(props.dateRange.end);

  return t('analytics.consumption_for_to', {
    start: start.format('DD/MM/YYYY'),
    end: end.format('DD/MM/YYYY'),
  });
});

const getCo2byKWh = computed(() => useCO2().getCo2byKWhByMeterId(parseInt(route.params.id as string)));

watchEffect(() => {
  if (childMeters.value.length === 0) {
    appStore().setGroupChild('meter');
  }
});

// Watchers
watch(
  () => props.dateRange,
  () => {
    reload(props.dateRange);
  },
  { deep: true },
);
</script>

<template>
  <div>
    <div class="flex flex-col gap-3">
      <div class="grid gap-4 grid-cols-3">
        <ui-stat-card
          :loading="loading"
          :title="$t('analytics.total_consumption')"
          icon="Gauge"
          type="consumption"
          :data="totalConsumption"
        />
        <ui-stat-card :loading="loading" :title="$t('global.cost')" type="euro" :data="totalCost" />
        <ui-stat-card
          :loading="loading"
          :title="$t('global.impact_co2')"
          type="impact"
          icon="Earth"
          :data="totalConsumption * getCo2byKWh"
        />
      </div>
      <ui-card :title="title">
        <template #header>
          <ui-dropdown
            :placeholder="$t('global.show_child_meters')"
            :items="showByOption"
            :disabled="childMeters?.length === 0"
            :model-value="appStore().getGroupChild"
            @update:model-value="
              appStore().setGroupChild($event);
              reload({ start: props.dateRange.start, end: props.dateRange.end });
            "
          />
          <ui-dropdown
            :placeholder="$t('global.show_by')"
            :items="aggregateOption"
            :model-value="appStore().getAggregationLevel"
            @update:model-value="
              appStore().setAggregationLevel($event);
              reload({ start: props.dateRange.start, end: props.dateRange.end });
            "
          />
        </template>
        <!-- Information -->
        <div v-if="aggregationLevel === 'week'">
          <ui-info
            v-if="alert"
            class="mb-4"
            :message="$t('global.alert_set_as', { value: alert?.params.standard_value }) + ' kWh'"
            :link="$t('global.update')"
            @click-link="modal = true"
          />
          <ui-info v-else class="mb-4" :link="$t('global.create')" :message="$t('global.no_alert_set')" @click-link="modal = true" />
        </div>

        <!-- Error -->
        <ui-error
          v-if="!loading && data.length === 0"
          :title="$t('error.title')"
          :message="$t('error.impossible_to_reatrive_consumption_data')"
        />
        <ui-info
          v-if="datasets[0]?.data?.length === 0 && !loading"
          :message="aggregationLevel === 'month' ? $t('error.your_month_is_not_complete') : $t('error.your_week_is_not_complete')"
        />
        <consumption-graph
          v-else-if="loading || datasets[0]?.data?.length > 0"
          :meter-id="parseInt(route.params.id as string)"
          :data="data"
          :datasets="datasets"
          :timestamps="timestamps"
          :loading="loading"
          :aggregation-level="aggregationLevel"
          :alert="alert"
          @label-click="onLabelClick"
        />
      </ui-card>
    </div>

    <alert-modal v-model="modal" type="weekly_consumption" :alert-id="alert?.id" />
    <consumption-zoom-modal
      v-if="zoomModal && zoomPeriod && zoomMeter"
      v-model="zoomModal"
      :meter-id="zoomMeter"
      :aggregation-level="aggregationLevel"
      :period="zoomPeriod"
    />
  </div>
</template>
